<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content">
            <div class="content_row flex">
                <label class="info" for="">项目名称</label>
                  <div class="content_right">
                    {{data.name}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">关联合同</label>
                  <div class="content_right">
                    <span  v-for="(item,index) in orderList" :key="index">
                        <span v-if="item.id==data.orderid">{{item.osn}}</span>
                    </span>
                     <!-- <a-select v-model="data.orderid" class=" ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in orderList" :key="index">{{item.osn}}</a-select-option>
                    </a-select> -->
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">项目开始时间</label>
                  <div class="content_right">
                      {{createtime}}
                       <!-- <a-date-picker v-model=""  size="large" @change="onPickCTime" /> -->
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">项目结束时间</label>
                  <div class="content_right">
                      {{endtime}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">客户</label>
                  <div class="content_right">
                    <span v-for="(item,index) in clientList" :key="index">
                        <span v-if="item.tmjcpb_corp__ids==data.corpid">{{item.tmjcpb_corp__name}}</span>
                    </span>
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">项目分类</label>
                  <div class="content_right">
                      {{data.project_type}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">地址</label>
                  <div class="content_right">
                   {{data.address}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">负责人</label>
                  <div class="content_right">
                      <span v-for="(item,index) in staff" :key="index">
                        <span v-if="item.userid==data.responsibility">{{item.name}}</span>
                    </span>
                    <!-- <a-select v-model="data.responsibility"  class="ant-select-lg">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">参与人</label>
                  <div class="content_right">
                      <span v-for="(item,index) in tempList" :key="index">
                        <span v-if="selTemp.indexOf(item.id)!=-1" style="padding-right:10px">{{item.name}}</span>
                    </span>
                    <!-- <a-select  mode="multiple" v-model="selTemp"  class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">介绍人</label>
                  <div class="content_right">
                   {{data.introduce}}
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">提成</label>
                  <div class="content_right">
                    {{data.commission}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">实际天</label>
                  <div class="content_right">
                   {{data.actual_day}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">工人工资</label>
                  <div class="content_right">
                    {{data.actual_money}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">应收价格</label>
                  <div class="content_right">
                    {{data.originally}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">回款价格</label>
                  <div class="content_right">
                   {{data.payment_money}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">回款物品</label>
                  <div class="content_right">
                    {{data.payment_goods}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">是否完结</label>
                  <div class="content_right">
                    <span v-text="data.status==1?'未完结':'已完结'"></span>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">备注</label>
                <div class="content_right">
                  {{data.remarks}}
                </div>
            </div>
               <div style="font-size:24px;margin:50px 0 30px">
                工时记录
            </div>
            <table width="100%" style="line-height:45px">
                     <tr>
                        <th>出勤日期</th>
                        <th>工人姓名</th>
                        <th>出勤时长</th>
                        <th>工作内容</th>
                        <th>操作</th>
                    </tr>
                    <tr v-for="(item,index) in laborList" :key="index">
                        <td>{{item.date}}</td>
                        <td>{{item.temporary.join(',')}}</td>
                        <td>{{item.time}}</td>
                        <td>{{item.sname}}</td>
                        <td class="action1"><span @click="showEditLabor(item)">编辑</span><span @click="delLabor(item.id)">删除</span></td>
                    </tr>
                </table>
            <a-modal v-model="showModal" :centered="true" title="添加工时" @ok="editLabor()">
                <div class="addBox" style="padding-bottom:50px">
                    <div class="add_title" >请选择工人</div>
                    <a-select v-model="onTemp" mode="multiple" class="ant-select-lg" style="width: 350px;">
                            <a-select-option :value="item.id" v-for="(item,index) in ontempList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                    <div class="add_title">请输入工时</div>
                    <a-input class="add_inp ant-input-lg" type = "number" style="width: 350px;" v-model="onLabor.time" step = "0.1"/>
                    <div class="add_title">请选择出勤日期</div>
                    <a-date-picker v-model="onLabor.date" @change="onPickTime" style="width: 350px;" size="large" />
                    <div class="add_title">请选择工作内容</div>
                    <a-select v-model="onLabor.skillid" class="ant-select-lg" style="width: 350px;">
                        <a-select-option :value="item.id" v-for="(item,index) in skillid" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </a-modal>
            </div>
        </div>
        </div>
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml,getDateTime} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "orderid": '',
                "createtime": "",
                "endtime": "",
                "corpid": '',
                "project_type": "",
                "address": "",
                "responsibility": "",
                "introduce":'',
                "commission":'',
                "actual_day": "",
                "actual_money": "",
                "payment_money": "",
                "payment_goods": "",
                "status": 1,
                "remarks": "",
            },
            createtime:'', //开始时间
            endtime:'',    //结束时间
            selSkillid:'',  //技能选项
            clientList:'', //客户列表
            orderList:'', //合同列表
            // 工时相关
            laborList:'', //
            showModal:false,  //修改工时弹框
            tempList:[], //工人列表
            onLabor:'',  //编辑状态工时
            onTemp:[],
            // tempList:'',  //工人列表
            selTemp:undefined,  //已选工人数组（参与人）
            typeList:'',  //项目分类数组
            ontempList:'', //可选工人
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.getStaff();
        this.getClient();
        this.getOrder();
        this.getSkillid();
         if(this.id){
            this.getDetail();
            this.getLabor();
            this.getTemp();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
         submit(){
            let url='/jieb/Temporary/interviewadd';
               var time=new Date().getTime();
            let data={
                'temporaryid':this.id,
                'record':this.record,
                'time':Math.round(time/1000)
            }
             requestXml(url,"POST",(status,res) => {
                console.log(status,res);
                if(status!='error'){
                    this.$message.success('成功');
                    this.getRecord();
                    this.record='';
                }
                if(res){
                    this.$message.info(res.msg);
                }
            },data)
        },
         onPickTime(date, dateString){
            console.log(date);
           this.onLabor.date=dateString;
        },
           // 获取工人列表
        getTemp(){
           requestXml("/jieb/Project/tempbase","GET",(res) => {
                this.tempList=res;
            })
        },
           // 获取工人列表
        getOnTemp(id){
           requestXml("/jieb/Project/attendanceadd","GET",(res) => {
                this.ontempList=res;
            },{id})
        },
        showEditLabor(item){
            item.skillid=Number(item.skillid);
            let onTemp=item.temporaryid.split(',');
            for(let i=0;i<onTemp.length;i++){
                onTemp[i]=Number(onTemp[i]);
            }
            this.onTemp=onTemp;
            this.onLabor= Object.assign({}, item);
            this.showModal=true;
        },
        delLabor(id){
            requestXml("/jieb/Project/attendancedel","POST",() => {
                   this.getLabor();
            },{id:id})
        },
        editLabor(){
            requestXml("/jieb/Project/attendanceedit","POST",() => {
                this.getLabor();
                this.showModal=false;
            },{id:this.onLabor.id,projectid:this.id,temporaryid:this.onTemp.join(','),skillid:this.onLabor.skillid,time:this.onLabor.time,date:this.onLabor.date});
        },
        getLabor(){
              requestXml("/jieb/Project/attendanceshow","POST",(res) => {
                console.log(res);
               this.laborList=res.list;
            },{projectid:this.id});
        },
        // 选择开始时间
        onPickCTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.createtime= Math.round(time/1000); 
        },
         onPickETime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.endtime= Math.round(time/1000); 
        },
        // 获取合同数据
        getOrder(){
            requestXml("/Scrm/Order/getMyList","POST",(res) => {
                console.log(res);
             this.orderList=res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
        // 获取客户列表
        getClient(){
            requestXml("/scrm/Corp/getUnBindCorp","POST",(res) => {
                this.clientList=res;
                console.log(res);
            })
        },
        // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
         getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Project/projectedit","GET",(res) => {
               console.log(res);
                this.data = Object.assign({}, res);
               if(res.orderid==0){res.orderid=''}
                this.createtime=getDateTime(res.createtime);
               this.endtime=getDateTime(res.endtime);
                this.selTemp=res.json.split(',');
                this.getOnTemp(this.id);
               for(let i in this.selTemp){
                   this.selTemp[i]=Number(this.selTemp[i]);
               }
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    .add_title{margin-bottom:30px;margin-top:30px}

    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    .action1 span{text-decoration:underline; cursor: pointer;margin: 0 10px;}
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
